@import './variables';

@media (prefers-color-scheme: dark) {
  .header,
  .aside,
  .aside-menu,
  .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading, .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: $dark-100 !important;
  }

  body,
  .bg-white {
    background-color: $dark-66 !important;
  }

  .header-fixed.subheader-fixed .subheader {
    background-color: $dark-33 !important;
    border-color: $dark-100;
    color: $light-66;
  }

  .text-dark {
    color: $light-66 !important;
  }

  .card {
    background-color: $dark-33;
    color: $light-66;

    &.card-custom > .card-header {
      border-color: $dark-100;

      .card-title,
      .card-title .card-label {
        color: $light-66;
      }
    }
  }

  .table {
    color: $light-33;

    &:not(.table-bordered) thead th,
    &:not(.table-bordered) thead td,
    th,
    td {
      border-color: $dark-100;
    }
  }

  .alert.alert-custom.alert-default {
    background-color: $dark-66;
    border-color: $dark-33;
    color: $light-33;
  }

  .symbol {
    &.symbol-light-success {
      .symbol-label {
        background-color: $dark-66;
        color: $light-33;
      }
    }

    &.symbol-light {
      .symbol-label {
        background-color: $dark-66;
        color: $light-33;
      }
    }
  }

  .form-control {
    background-color: $dark-66;
    border-color: $dark-100;
    color: $light-33 !important;

    &.form-control-solid {
      background-color: $dark-33;
      border-color: $dark-100;
      color: $light-33 !important;

      &:active,
      &.active,
      &:focus,
      &.focus {
        background-color: $dark-66;
        border-color: $dark-100;
        color: $light-33 !important;
      }
    }
  }

  .react-loading-skeleton {
    background-color: $dark-16 !important;
    background-image: linear-gradient(90deg, $dark-16, $dark-33, $dark-16) !important;
  }

}

.selectContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .selectBox {
        width: 20%;
        height: 5rem;
        background-color: black;
        border-radius: 5px;
        padding: .25rem;

        .header {
            color: white;
            width: 100%;
            height: 2rem;
            line-height: 2rem;
            font-size: 1rem;
            padding-left: .5rem;
        }   
        .select {
            background-color: black;
            color: white;
            width: 100%;
            height: 2rem;
            font-size: 1.5rem;
            border-color: black !important;
        }
    }
}

.tableContainer {
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    .head {
        display: flex;
        flex-direction: row;
    
        .tableHead {
            width: 16%;
            height: 5rem;
            color: white;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }

    .tableContent {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid rgb(152, 152, 152);
        .table {
            width: 16%;
            height: 6rem;
            color: white;
            font-size: 1.2rem;
            font-weight: 600;
            align-items: center;
            display: flex;

            .img {
                width: 3rem;
                height: 3rem;
                border-radius: 5px;
                margin: 1rem;
            }
            .box {
                background-color: red;
                border-radius: 5px;
                padding: 1rem;
                width: fit-content;
            }
        }
    }
}